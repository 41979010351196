import React, { useState } from 'react'
import { ethers } from 'ethers'
import FirstPlaceImage from '../image/win1.png';
import SecondPlaceImage from '../image/win2.png';
import ThirdPlaceImage from '../image/win3.png';

const Topplace = ({ object, day }) => {
    //Name, LotteryCA, object.day, object.openRule, object.decimal
    const [top, setTop] = useState("0x00...0000")
    const [top2, setTop2] = useState("0x00...0000")
    const [top3, setTop3] = useState("0x00...0000")
    const [topAmount, setTopAmount] = useState("0")
    const [top2Amount, setTop2Amount] = useState("0")
    const [top3Amount, setTop3Amount] = useState("0")
    let rule = true
    let theDay = "Yesterday";

    const checkDay = async () => {
        if (day === 0) {
            theDay = "Today"
            return;
        }
        rule = false;
        return
    }

    const getTopThreeData = async () => {
        try {
            let timestamp = new Date().getTime();
            let time = Math.floor(timestamp / 1000);
            let today = Math.floor(time / 600);

            let FirstThree = await object.LotteryCA.viewFirstThreePlace(today - day);

            setTop(`${FirstThree[0].slice(0, 4)}...${FirstThree[0].slice(-4)}`)
            setTop2(`${FirstThree[1].slice(0, 4)}...${FirstThree[1].slice(-4)}`)
            setTop3(`${FirstThree[2].slice(0, 4)}...${FirstThree[2].slice(-4)}`)

            let result = await object.LotteryCA.viewFirstThreePlaceAmount(today - day)

            let topNum = Number(result[0])
            let top2Num = Number(result[1]);
            let top3Num = Number(result[2]);
            setTopAmount(topNum / Math.pow(10, object.decimal))
            setTop2Amount(top2Num / Math.pow(10, object.decimal))
            setTop3Amount(top3Num / Math.pow(10, object.decimal))

        } catch (err) {
            console.log(err)
            setTimeout(async () => {
                await getTopThreeData()
                return
            }, 1000)
            return;
        }

        return;
    }

    const getData = async () => {
        await checkDay();
        await getTopThreeData();
    }

    getData()

    return (
        <div>
            <div className="FirstThreeTable_Title">
                <div className="titlespan"></div>
                <h5 className="FirstThreeTable_TitleContent">{object.Name} Top Three {theDay}</h5>
                {rule && <h5 className="FirstThreeTable_Rule" align="right" id="rule" onClick={() => object.openRule(0)}>Rule</h5>}
            </div>
            <div className="FirstThreeTable_Content">
                <div className="FirstThreeTable_Picture">
                    <div className="FirstThreeTable_SecondPlace">
                        <img src={SecondPlaceImage} id="SecondPlaceImage" alt="Second Place" />
                        <h5 align="middle">{top2}</h5>
                        <h5 align="middle">{top2Amount}</h5>
                    </div>
                    <div className="FirstThreeTable_FirstPlace">
                        <img src={FirstPlaceImage} id="FirstPlaceImage" alt="First Place" />
                        <h5 align="middle">{top}</h5>
                        <h5 align="middle">{topAmount}</h5>
                    </div>
                    <div className="FirstThreeTable_ThridPlace">
                        <img src={ThirdPlaceImage} id="ThirdPlaceImage" alt="Third Place" />
                        <h5>{top3}</h5>
                        <h5>{top3Amount}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topplace
