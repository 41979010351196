import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers'
import contractABI from './contractabi/contractabi.json'
import tokenABI from './contractabi/tokenabi.json'
// Two token use the same token abi since we only use allowance and approve
import TopthreeAndHistory from './components/TopthreeAndHistory';
import WheelVideo from './video/wheel.mp4'

import ModalSD from './modals/ModalSD';
import ModalLS from './modals/ModalLS';
import ModalGame from './modals/ModalGame';
import ModalRule from './modals/ModalRule';
import ModalFOXLS from './modals/ModalFOXLS'
import ModalFOXSD from './modals/ModalFOXSD'

import BannerImage from './image/FOXTBanner3.png';
import Ranking from './image/Ranking.png';
import WheelImage from './image/wheel.jpg'
import winImage from './image/win.jpg'
import loseImage from './image/lose.jpg'

// const FOXLotteryAddress = "0x1E8878a3C49131a85840bcd8Ff0F78c495435023";
// const FOXTLotteryAddress = "0x2CBf52f8D466105e9AC57f54C8FEF1600f8FFA5B";   //LotteryCA
// const FOXtokenAddress = "0x211f799afB5839021707C9f7bA9c9E05Cdb134c7";
// const FOXTtokenAddress = "0xfA29232733F5066000Cf3d3280Df41675aA97729";      //TokenCA
const FOXLotteryAddress = "0xca651Ab0F67f375603360f57542ABd74d2b5b3FB";
const FOXTLotteryAddress = "0x2ff578635121fa0D7e493B76a0E352879434403d";   //LotteryCA
const FOXtokenAddress = "0x39ee2f7b7440c540A5cbCb26b19BCB6a2e6aD4B1";
const FOXTtokenAddress = "0x4B0a20d459f50e1e593AcDAa9635CA80b5E2Adec";      //TokenCA

function App() {
  /*
    預先設定的參數
  */

  const FOXT = "FOXT"
  const FOX = "FOX"
  const [FoxtLoaded, setFoxtLoaded] = useState(false);
  const [FoxLoaded, setFoxLoaded] = useState(false);

  const [modalRuleIsOpen, setModalRuleIsOpen] = useState(false);
  const [modalGameIsOpen, setModalGameIsOpen] = useState(false);
  const [modalSDIsOpen, setModalSDIsOpen] = useState(false);
  const [modalLSIsOpen, setModalLSIsOpen] = useState(false);
  const [modalFOXSDIsOpen, setModalFOXSDIsOpen] = useState(false);
  const [modalFOXLSIsOpen, setModalFOXLSIsOpen] = useState(false);

  const [endTime, setEndTime] = useState(0);

  const [foxtTotalBet, setFoxtTotalBet] = useState("0");
  const [foxTotalBet, setFoxTotalBet] = useState("0");
  const [foxtData, setFoxtData] = useState([])
  const [foxData, setFoxData] = useState([])


  const [FOXTBalance, setFOXTBalance] = useState('0')
  const [FOXBalance, setFOXBalance] = useState('0')
  const [defaultAccount, setDefaultAccount] = useState(null)
  const [connectButtonText, setConnectButtonText] = useState('Connect Wallet')
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [correctNetwork, setCorrectNetwork] = useState(null);

  const [FOXTLotteryCA, setFOXTLotteryCA] = useState(null);
  const [FOXLotteryCA, setFOXLotteryCA] = useState(null);

  const [foxTokenContract, setFoxTokenContract] = useState(null);
  const [foxtTokenContract, setFOXTTokenContract] = useState(null);

  const [isWinImageOpen, setIsWinImageOpen] = useState(false)
  const [isLoseImageOpen, setIsLoseImageOpen] = useState(false)


  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (FoxLoaded)
      setFoxLoaded(false)
    if (FoxtLoaded)
      setFoxtLoaded(false)
  };

  const videoRef = useRef(null);

  const handleTimeUpdate = () => {
    let videoID = document.getElementById("vid");
    if (videoRef.current.currentTime >= (endTime - 0.1) && endTime !== 0) {
      videoRef.current.pause();

      if (isLoseImageOpen) {
        let lose = document.getElementById("losepic");
        lose.hidden = false;
      }
      if (isWinImageOpen) {
        let win = document.getElementById("winpic");
        win.hidden = false;
      }

      setTimeout(() => {
        videoID.hidden = true
      }, 3000);
      setIsWinImageOpen(false)
      setIsLoseImageOpen(false)
    }
  }
  const EndVid = () => {
    if (videoRef.current.currentTime < 3) return;

    let videoID = document.getElementById("vid");
    videoRef.current.currentTime = endTime;
    videoRef.current.pause()

    if (isLoseImageOpen) {
      let lose = document.getElementById("losepic");
      lose.hidden = false;
    }
    if (isWinImageOpen) {
      let win = document.getElementById("winpic");
      win.hidden = false;
    }

    setTimeout(() => {
      videoID.hidden = true
    }, 3000);
    setIsWinImageOpen(false)
    setIsLoseImageOpen(false)
  }

  const closePic = () => {
    let win = document.getElementById("winpic")
    let lose = document.getElementById("losepic")
    win.hidden = true;
    lose.hidden = true;
  }

  const handleVideoEnded = () => {
    let videoID = document.getElementById("vid");
    setTimeout(() => {
      videoID.hidden = true
    }, 3000);
  };

  const isWin = (value) => {
    console.log("receive is win value :" + value)
    if (value > 0.5) {
      setIsWinImageOpen(true)
    }
    if (value < 0.5) {
      setIsLoseImageOpen(true)
    }
  }
  const playVideo = (value) => {
    try {
      setModalGameIsOpen(false)
      setModalLSIsOpen(false);
      setModalSDIsOpen(false);


      let videoID = document.getElementById("vid");
      videoID.hidden = false;
      if (value === 5) {
        /* Todo : Make a wheel spin with 5-9*/
        let random = RandomNumber(16.2, 14.5)
        setEndTime(random)
      }
      if (value === 0) {
        /* Todo : Make a wheel spin with 0-4*/
        let random = RandomNumber(19, 16.5)
        setEndTime(random)
      }
      if (value === 1) {
        /* Todo : Make a wheel spin with 1 3 5 7 9*/
        let random = Math.random()
        if (random < 0.2)
          setEndTime(18)
        if (random < 0.4 && random >= 0.2)
          setEndTime(17)
        if (random < 0.6 && random >= 0.4)
          setEndTime(16.2)
        if (random < 0.8 && random >= 0.6)
          setEndTime(15.4)
        if (random < 1 && random >= 0.8)
          setEndTime(14.5)
      }
      if (value === 2) {
        /* Todo : Make a wheel spin with 0 2 4 6 8*/
        let random = Math.random()
        if (random < 0.2)
          setEndTime(19)
        if (random < 0.4 && random >= 0.2)
          setEndTime(17.5)
        if (random < 0.6 && random >= 0.4)
          setEndTime(16.5)
        if (random < 0.8 && random >= 0.6)
          setEndTime(15.8)
        if (random < 1 && random >= 0.8)
          setEndTime(15)
      }

      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    } catch { }
  };

  const RandomNumber = (upper, lower) => {
    let randomNum = Math.random()
    console.log("Random " + randomNum)
    return (upper - lower) * (randomNum.toFixed(2)) + lower;
  }

  const handleGameModalClose = (value) => {
    console.log("Handling game modal close : " + value)
    if (value === 3) {
      setModalFOXLSIsOpen(true)
      setModalGameIsOpen(false)
    }

    if (value === 2) {
      setModalFOXSDIsOpen(true)
      setModalGameIsOpen(false)
    }

    if (value === 1) {
      setModalLSIsOpen(true)
      setModalGameIsOpen(false)
    }

    if (value === 0) {
      setModalSDIsOpen(true)
      setModalGameIsOpen(false)
    }

    if (value === null)
      setModalGameIsOpen(false)
  }

  async function changingAccount() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        connectWalletHandler()
      })
    }
  }

  const handleRuleModal = (value) => {
    if (value === 0)
      setModalRuleIsOpen(true)
    else
      console.log("Not zero")
  }

  useEffect(() => {
    changingAccount()
    setTimeout(
      async function () {
        await getStatus()
        await getHistoryData()
      }, 1000)
  }, [defaultAccount])

  /*
    ==========================================
    ==========================================
    
      ***  Functions for All project   ***
    
    ==========================================
    ==========================================
  */
  const connectWalletHandler = async () => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(async (result) => {
          await accountChangeHandler(result[0]);
          setConnectButtonText(`${result[0].slice(0, 4)}...${result[0].slice(-4)}`);
        })
    } else {
      alert('Need to install MetaMask!')
    }
  }

  const accountChangeHandler = async (newAccount) => {
    checkCorrectNetwork();
    setDefaultAccount(newAccount);
    window.sessionStorage.setItem('account', newAccount)

    await updateEthers();
  }

  const updateEthers = async () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempFOXTLotteryContract = new ethers.Contract(FOXTLotteryAddress, contractABI, tempSigner)
    setFOXTLotteryCA(tempFOXTLotteryContract);

    let tempFOXLotteryContract = new ethers.Contract(FOXLotteryAddress, contractABI, tempSigner)
    setFOXLotteryCA(tempFOXLotteryContract);

    let tempFOXtokenContract = new ethers.Contract(FOXtokenAddress, tokenABI, tempSigner)
    setFoxTokenContract(tempFOXtokenContract);

    let tempTokenContract = new ethers.Contract(FOXTtokenAddress, tokenABI, tempSigner)
    setFOXTTokenContract(tempTokenContract);
  }

  const checkCorrectNetwork = async () => {
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    console.log('Connected to chain:' + chainId)

    const netWorkID = '0x38'

    if (chainId !== netWorkID) {
      setCorrectNetwork(false)
      // alert("Please Connect to the Correct Network")
    } else {
      setCorrectNetwork(true)
    }
  }


  /*
    ==========================================
    ==========================================
    
      ***  Functions for this project   ***
    
    ==========================================
    ==========================================
  */

  const getStatus = async () => {
    try {
      let foxtDecimal = await foxtTokenContract.decimals();
      if (foxtDecimal === null) return;

      let foxDecimal = await foxTokenContract.decimals();
      if (foxDecimal === null) return;

      let tempFoxtBalance = await foxtTokenContract.balanceOf(defaultAccount);
      if (tempFoxtBalance === null) return;

      let tempFoxBalance = await foxTokenContract.balanceOf(defaultAccount);
      if (tempFoxBalance === null) return;

      await getBetInformation()

      let tempFOXTBalanceDividedByDecimal = (tempFoxtBalance / Math.pow(10, foxtDecimal)).toFixed(2);
      let tempFOXBalanceDividedByDecimal = (tempFoxBalance / Math.pow(10, foxDecimal)).toFixed(2);

      setFOXTBalance(tempFOXTBalanceDividedByDecimal);
      setFOXBalance(tempFOXBalanceDividedByDecimal);
    } catch (err) { console.log(err) }
  }

  const getBetInformation = async () => {
    let result = await FOXTLotteryCA.userInfo(defaultAccount)
    if (result === null) return;
    let result2 = await FOXLotteryCA.userInfo(defaultAccount)
    if (result2 === null) return;
    setFoxtTotalBet(result.toString());
    setFoxTotalBet(result2.toString());
  }

  const getHistoryData = async () => {
    if (!defaultAccount) return;
    try {
      let result1 = await FOXTLotteryCA.viewHistory2(defaultAccount);
      setFoxtData(result1);
      let result2 = await FOXLotteryCA.viewHistory2(defaultAccount);
      setFoxData(result2);

    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div className="App">
      <div className="App-header">
        <button onClick={connectWalletHandler} id="ConnectButton" >{connectButtonText}</button>
      </div>

      <div className="App-body">
        <img
          src={winImage}
          alt="You win"
          id="winpic"
          hidden
          onClick={closePic}
        />
        <img
          src={loseImage}
          alt="You lose"
          id="losepic"
          hidden
          onClick={closePic}
        />

        <video
          ref={videoRef}
          src={WheelVideo}
          id="vid"
          hidden
          onEnded={handleVideoEnded}
          onClick={EndVid}
          onTimeUpdate={handleTimeUpdate}
        />

        <div className="BannerDiv">
          <span>
            <img src={BannerImage} id="BannerImage" />
          </span>
        </div>
        <div className='BalanceDiv'>
          <h5 align="left" id="balanceLeft">FOXT Balance : {FOXTBalance}</h5>
          <h5 align="right" id="balanceRight">FOX Balance : {FOXBalance}</h5>
        </div>
        <div className="BannerDiv">
          <span>
            <img src={WheelImage} id="LotteryImage" onClick={() => setModalGameIsOpen(true)} />
          </span>
        </div>

        <div className="GuessDiv">
          <div className="Modal">

            <ModalGame
              open={modalGameIsOpen}
              onClose={handleGameModalClose}
            />

            <ModalSD
              open={modalSDIsOpen}
              onClose={() => setModalSDIsOpen(false)}
              TokenContract={foxtTokenContract}
              defaultAccount={defaultAccount}
              LotteryAddress={FOXTLotteryAddress}
              LotteryCA={FOXTLotteryCA}
              playVideo={playVideo}
              isWin={isWin}
            />

            <ModalLS
              open={modalLSIsOpen}
              onClose={() => setModalLSIsOpen(false)}
              TokenContract={foxtTokenContract}
              defaultAccount={defaultAccount}
              LotteryAddress={FOXTLotteryAddress}
              LotteryCA={FOXTLotteryCA}
              playVideo={playVideo}
              isWin={isWin}
            />

            <ModalFOXSD
              open={modalFOXSDIsOpen}
              onClose={() => setModalFOXSDIsOpen(false)}
              TokenContract={foxTokenContract}
              defaultAccount={defaultAccount}
              LotteryAddress={FOXLotteryAddress}
              LotteryCA={FOXLotteryCA}
              playVideo={playVideo}
              isWin={isWin}
            />

            <ModalFOXLS
              open={modalFOXLSIsOpen}
              onClose={() => setModalFOXLSIsOpen(false)}
              TokenContract={foxTokenContract}
              defaultAccount={defaultAccount}
              LotteryAddress={FOXLotteryAddress}
              LotteryCA={FOXLotteryCA}
              playVideo={playVideo}
              isWin={isWin}
            />

            <ModalRule
              open={modalRuleIsOpen}
              onClose={() => setModalRuleIsOpen(false)} />
          </div>
        </div>
        <div className='options'>
          <br />
          <span>
            <img src={Ranking} id="LotteryImage" onClick={toggleMenu} />
          </span>
          {isOpen && (
            <ul>
              <li onClick={() => {
                setFoxLoaded(false)
                setFoxtLoaded(true)
              }}>Check FOXT</li>
              <br />
              <li onClick={() => {
                setFoxtLoaded(false)
                setFoxLoaded(true)
              }}>Check FOX</li><br />
              <li onClick={() => {
                setFoxLoaded(false)
                setFoxtLoaded(false)
                setIsOpen(false)
              }}>Hide</li>
            </ul>
          )}
        </div>

        {FoxtLoaded && <TopthreeAndHistory
          Name={FOXT}
          LotteryCA={FOXTLotteryCA}
          openRule={handleRuleModal}
          decimal={6}
          data={foxtData}
          bet={foxtTotalBet}
        />}

        {FoxLoaded && <TopthreeAndHistory
          Name={FOX}
          LotteryCA={FOXLotteryCA}
          openRule={handleRuleModal}
          decimal={0}
          data={foxData}
          bet={foxTotalBet}
        />}

      </div>
    </div>
  );
}

export default App;
